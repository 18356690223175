<template>
  <div class="project" :class="{selected: selected}" :style="{'background-image': 'url(' + logo + ')'}" @click="onSelect">
    <transition name="fade">
      <div v-if="selected" class="dismiss" @click="onUnselect"></div>
    </transition>
    <h4 class="title">{{title}}</h4>
    <div class="subtitle">{{subtitle}}</div>
    <transition name="zoom">
      <img v-if="image && selected" class="image" :src="image"/>
    </transition>
    <div>
      <div v-for="(p, index) of description" :key="index" v-html="p"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "project",
  props: ["logo", "title", "subtitle", "image", "description"],
  data: function() {
    return {
      selected: false
    };
  },
  methods: {
    onSelect: function() {
      this.selected = true;
    },
    onUnselect: function(ev) {
      this.selected = false;
      let evt = ev ? ev : window.event;
      if (evt.stopPropagation) {
        evt.stopPropagation();
      } else {
        evt.cancelBubble = true;
      }
    }
  }
};
</script>

<style scoped>
.project {
  position: relative;
  padding: 10px 10px 10px 110px;
  margin-bottom: 15px;
  background: no-repeat scroll transparent 10px 10px;
  background-size: 60px;
  border-radius: 10px;
  border: 1px solid transparent;
  line-height: 30px;
  transition: ease-in-out 0.4s;
}

.project:hover {
  background-color: #efefef;
  cursor: pointer;
}
.project.selected {
  border-color: #eee;
}

.project.selected:hover {
  background-color: transparent;
  cursor: unset;
}

.title {
  margin: 0;
}

.subtitle {
  font-size: 13px;
  margin-bottom: 10px;
}
@media (max-width: 767px) {
  .project {
    padding: 10px;
    background-position: 10px 20px;
  }
  .title {
    margin-top: 10px;
    margin-left: 80px;
  }
  .subtitle {
    margin-left: 80px;
  }
}

.image {
  display: block;
  margin: 15px auto;
  width: 80%;
}
@media (max-width: 767px) {
  .image {
    width: 80%;
  }
}
.zoom-enter-active,
.zoom-leave-active {
  transition: all ease-in-out 0.5s;
}
.zoom-enter,
.zoom-leave-to {
  width: 0;
  opacity: 0;
}

.dismiss {
  position: absolute;
  right: 10px;
  width: 32px;
  height: 32px;
  background: url("/img/icon_close.png") no-repeat center transparent;
}
.dismiss:hover {
  cursor: pointer;
}
.fade-enter-active,
.fade-leave-active {
  transition: all ease-in-out 0.5s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>