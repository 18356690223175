<template>
  <header>
    <div class="title">个人简历</div>
    <transition name="fade-navigator" mode="out-in">
      <div v-if="switchable && active === 'prime'" class="switch aside" @click="onSwitch" key="aside"></div>
      <div v-if="switchable && active === 'aside'" class="switch prime" @click="onSwitch" key="prime"></div>
    </transition>
  </header>
</template>

<script>
export default {
  name: "navigation-bar",
  props: ['active', 'switchable'],
  methods: {
    onSwitch() {
      let newActive;
      if (this.active === 'prime') {
        newActive = 'aside';
      } else {
        newActive = 'prime';
      }
      this.$emit('update:active', newActive);
    }
  }
};
</script>

<style scoped>
header {
  z-index: 100;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background-color: #fff;
  text-align: center;
  box-shadow: 0 5px 5px whitesmoke;
}
.title {
  font-size: 18px;
  padding: 10px 0;
  letter-spacing: 2px;
  color: #1d1e1f;
}
.switch {
  position: absolute;
  width: 20px;
  height: 20px;
  right: 10px;
  top: calc(50% - 10px);
}
.switch.prime {
  background: url('/img/icon_detail.png') no-repeat center center;
  background-size: 100%
}
.switch.aside {
  background: url('/img/icon_portrait.png') no-repeat center center;
  background-size: 100%
}
@media (min-width: 768px) {
  .title {
    font-size: 25px;
  }
  .switch {
    display: none;
  }
}

.fade-navigator-enter-active,
.fade-navigator-leave-active {
  transition: all 0.2s;
}
.fade-navigator-enter,
.fade-navigator-leave-to {
  opacity: 0;
}
</style>