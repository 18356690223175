<template>
  <div class="career">
    <span v-if="time">{{time}}</span>
    <span style="margin-left: 10px">{{name}}</span>
    <span v-if="duration" style="margin-left: 10px">{{ duration }}</span>
    <img class="logo" :src="logo">
    <ul><li v-for="(item, index) of content" :key="index" v-html="item"></li></ul>
  </div>
</template>

<script>
export default {
  name: "career",
  props: ["time", "name", "logo", "content", 'duration']
};
</script>

<style scoped>
.career {
  line-height: 30px;
}

.logo {
  display: block;
  float: right;
  margin: 20px auto;
}
@media (max-width: 767px) {
  .career {
    text-align: center;
  }
  .career li {
    text-align: left;
  }
  .logo {
    float: none;
  }
}
</style>