<template>
  <div class="prime">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "prime"
};
</script>

<style scoped>
.prime {
  width: 100%;
  color: #999;
  margin: 30px 0;
}
.prime > * {
  margin-bottom: 10px;
  padding: 30px;
}
@media (min-width: 768px) {
  .prime {
    margin: 30px 10px 30px 20px;
    max-width: calc(100% - 382px);
    /*float: left;*/
  }
  .prime > * {
    border-bottom: 1px solid #eee;
  }
  .prime > *:last-child {
    border-bottom: none;
  }
}
</style>