<template>
  <div class="career">
    <prime-title title="工作经历" />
    <career v-for="(career, index) of data" :key="index"
      :time="career.time" :name="career.name" :logo="career.logo"
      :duration="career.duration"
      :content="career.content"
    />
  </div>
</template>

<script>
import PrimeTitle from '@/components/PrimeTitle.vue';
import Career from '@/components/Career.vue';

export default {
  name: 'career-list',
  props: {
    data: {
      type: Array,
      default: () => []
    }
  },
  components: {
    PrimeTitle,
    Career
  }
};
</script>