<template>
  <div class="contract">
    <widget-title title="联系方式" />
    <transition name="fade" mode="out-in">
      <div v-if="folded && count >= 4">
        <table><tr>
          <td v-if="data.phone"><a target="_blank" :href="`tel://${data.phone}`">
            <img src="img/icon_phone.png"/>
          </a></td>
          <td v-if="data.qq"><a target="_blank" :href="`http://wpa.qq.com/msgrd?v=3&uin=${data.qq}&site=qq&menu=yes`">
            <img src="img/icon_qq.png"/>
          </a></td>
          <td v-if="data.mail"><a target="_blank" :href="`mailto:${data.mail}`">
            <img src="img/icon_mail.png"/>
          </a></td>
          <td v-if="data.site"><a target="_blank" :href="data.site">
            <img src="img/icon_wordpress.png"/>
          </a></td>
        </tr></table>
        <div class="list" @click="folded = false">展开</div>
      </div>

      <table v-else class="detail">
        <tr v-if="data.phone">
          <td style="width: 60px"><a target="_blank" :href="`tel://${data.phone}`">
            <img src="img/icon_phone.png"/>
          </a></td>
          <td>{{data.phone}}</td>
        </tr>
        <tr v-if="data.qq">
          <td style="width: 60px"><a target="_blank" :href="`http://wpa.qq.com/msgrd?v=3&uin=${data.qq}&site=qq&menu=yes`">
            <img src="img/icon_qq.png"/>
          </a></td>
          <td>{{data.qq}}</td>
        </tr>
        <tr v-if="data.mail">
          <td><a target="_blank" :href="`mailto:${data.mail}`">
            <img src="img/icon_mail.png"/>
          </a></td>
          <td>{{data.mail}}</td>
        </tr>
        <tr v-if="data.site">
          <td style="width: 60px"><a target="_blank" :href="data.site">
            <img src="img/icon_wordpress.png"/>
          </a></td>
          <td>{{data.site}}</td>
        </tr>
      </table>
    </transition>
  </div>
</template>

<script>
import WidgetTitle from '@/components/WidgetTitle.vue';

export default {
  name: 'contract',
  components: { WidgetTitle },
  props: {
    data: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      folded: true
    };
  },
  computed: {
    count() {
      return Object.keys(this.data).length
    }
  }
};
</script>

<style scoped>
table {
  width: 100%;
  text-align: center;
}
.list {
  margin-top: 20px;
  text-align: center;
  color: lightgray;
}
.list:hover {
  cursor: pointer;
}
.detail {
  text-align: center;
}

.fade-enter-active,
.fade-leave-active {
  transition: all 0.5s;
}
.fade-enter,
.fade-leave-to {
  transform: translateY(10px);
  opacity: 0;
}
</style>