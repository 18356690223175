<template>
  <div class="ability">
    <widget-title title="能力自评" />
    <progress-bar
      v-for="(ability, index) of abilities" :key="index" :title="ability.title"
      :color="ability.color" :progress="ability.progress"
    />
  </div>
</template>

<script>
import WidgetTitle from "@/components/WidgetTitle.vue";
import ProgressBar from "@/components/ProgressBar.vue";

export default {
  name: "ability",
  props: ["abilities"],
  components: { WidgetTitle, ProgressBar }
};
</script>