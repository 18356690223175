<template>
  <div class="portrait">
    <img class="photo" :src="photo"/>
    <h3 class="name">{{name}}</h3>
    <p class="job">{{job}}</p>
    <div v-if="working" class="working"><img src="img/icon_working.png"/><span>{{working}}</span></div>
  </div>
</template>

<script>
export default {
  name: "portrait",
  props: ["photo", "name", "working", "job"]
};
</script>

<style>
.portrait {
  text-align: center;
}
.photo {
  width: 200px;
  height: 200px;
  border-radius: 100px;
}
.name {
  color: black;
}
.working {
  font-size: 13px;
}
.working img {
  width: 15px;
  margin-right: 5px;
}
.working * {
  display: inline-block;
  vertical-align: middle;
}
</style>