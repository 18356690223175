<template>
  <div class="about-me">
    <prime-title title="关于我" />
    <p v-for="(content, index) of contents" :key="index" v-html="content" />
  </div>
</template>

<script>
import PrimeTitle from "@/components/PrimeTitle.vue";
export default {
  name: "about-me",
  props: ["contents"],
  components: { PrimeTitle }
};
</script>

<style scoped>
.about-me {
  line-height: 30px;
}
</style>