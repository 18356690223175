<template>
  <table class="bar">
    <tr>
      <td class="title">{{title}}</td>
      <td>
        <div class="border" :style="{'border-color': color}">
          <div class="fill" :style="{'border-color': color, 'background-color': color, 'width': 100 * progress + '%'}" />
        </div>
      </td>
    </tr>
  </table>
</template>

<script>
export default {
  name: "progress-bar",
  props: ["title", "progress", "color"]
};
</script>

<style scoped>
.bar {
  width: 100%;
}
.bar tr {
  width: 100%;
}

.title {
  width: 40%;
  padding: 5px;
  margin-right: 5px;
}
.border {
  border: 1px solid;
  height: 16px;
  min-width: 100px;
  width: 100%;
  border-radius: 8px;
  overflow: hidden;
}
.fill {
  border: 1px solid;
  height: inherit;
  margin: -1px 0 0 -1px;
  border-radius: 8px;
  animation: progressAnimation 1s;
}
@keyframes progressAnimation {
  from {
    width: 0;
  }
  to {
  }
}
</style>