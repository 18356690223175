<template>
  <div class="aside">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "side-bar"
};
</script>

<style scoped>
.aside {
  width: 100%;
  color: #999;
  margin: 30px 0;
}
.aside > * {
  padding: 30px;
}
@media (min-width: 768px) {
  .aside {
    margin: 30px 20px 30px 10px;
    max-width: 25%;
    min-width: 320px;
    float: right;
    border: 1px solid #eee;
  }
}
</style>