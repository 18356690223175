<template>
  <div class="skill">
    <widget-title title="专业技能" />
    <progress-bar
      v-for="(skill, index) of skills" :title="skill.title" :key="index"
      :color="skill.color" :progress="skill.progress"
    />
  </div>
</template>

<script>
import WidgetTitle from "@/components/WidgetTitle.vue";
import ProgressBar from "@/components/ProgressBar.vue";

export default {
  name: "skill",
  props: ["skills"],
  components: { WidgetTitle, ProgressBar }
};
</script>
