<template>
  <div class="project">
    <prime-title title="参与项目" />
    <project 
      v-for="(project, index) of projects" :key="index"
      v-show="!project.hidden"
      :logo="project.logo" :title="project.title" :subtitle="project.subtitle"
      :image="project.image" :description="project.description"
    />
  </div>
</template>

<script>
import PrimeTitle from "@/components/PrimeTitle.vue";
import Project from "@/components/Project.vue";

export default {
  name: "project-list",
  props: ["projects"],
  components: { PrimeTitle, Project }
};
</script>

<style scoped>
</style>