<template>
  <div class="content">
    <widget-title title="基本信息" />
    <div style="margin: 20px;">{{address}}</div>
    <div class="graduated">
      <div><p v-for="(item, index) of graduated" :key="index">{{item}}</p></div>
    </div>
  </div>
</template>

<script>
import WidgetTitle from "@/components/WidgetTitle.vue";

export default {
  name: "basic-information",
  components: { WidgetTitle },
  props: ["address", "graduated"]
};
</script>

<style>
.content {
  text-align: center;
  font-size: 15px;
}

.graduated > * {
  display: inline-block;
  margin: 10px;
  text-align: left;
}
.graduated:before {
  content: "";
  display: inline-block;
  width: 30px;
  height: 30px;
  background: url("/img/icon_graduated.png") no-repeat center center;
  background-size: 100%;
}
.graduated p {
  margin: 0;
}
</style>