<template>
  <h2 class="title">{{title}}</h2>
</template>

<script>
export default {
  name: "widget-title",
  props: ["title"]
};
</script>

<style scoped>
.title {
  color: black;
  font-size: 18px;
  font-weight: normal;
  padding-bottom: 20px;
  position: relative;
  text-align: center;
  margin: 0 0 18px;
}
@media (max-width: 767px) {
  .title {
    font-size: 20px;
    font-weight: 500;
  }
}

.title:before {
  bottom: 10px;
  margin-left: -40px;
  width: 80px;
  background-color: #eee;
  content: "";
  height: 1px;
  left: 50%;
  position: absolute;
}
@media (min-width: 767px) {
  .title:after {
    bottom: 0;
    margin-left: -25px;
    width: 50px;
    background-color: #eee;
    content: "";
    height: 1px;
    left: 50%;
    position: absolute;
  }
}
</style>