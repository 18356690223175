<template>
  <h2 class="title">{{title}}</h2>
</template>

<script>
export default {
  name: "prime-title",
  props: ["title"]
};
</script>

<style scoped>
.title {
  color: black;
  letter-spacing: 1px;
  padding-bottom: 20px;
  position: relative;
  text-align: center;
  margin: 0 0 18px;
  font-size: 20px;
  font-weight: 500;
}
.title:before {
  bottom: 10px;
  margin-left: -40px;
  width: 80px;
  background-color: #eee;
  content: "";
  height: 1px;
  left: 50%;
  position: absolute;
}
</style>