<template>
  <div id="app">
    <navigation-bar :switchable="this.$route.name === 'home'" :active="this.$store.state.active" @update:active="onActiveChanged" />

    <transition name="fade" mode="out-in">
      <router-view/>
    </transition>
  </div>
</template>

<script>
import NavigationBar from "@/components/NavigationBar.vue";

export default {
  components: {
    NavigationBar
  },
  methods: {
    onActiveChanged(input) {
      this.$store.commit('switchHome', input);
    }
  }
}
</script>


<style>
#app {
  font-family: "Microsoft Yahei", 微软雅黑, Arial;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  margin: 0;
}

a {
  color: lightskyblue;
}

.fade-enter-active,
.fade-leave-active {
  transition: all 0.5s;
}
.fade-enter,
.fade-leave-to {
  transform: translateY(10px);
  opacity: 0;
}
</style>
